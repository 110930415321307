<template>
  <div style="position: relative;">
    <div
      style="text-align: right;position: absolute;right: 18px;top:8px;z-index:99"
     >
      <span style="font-size: 14px;">账期选择：</span
      ><qzf-search-period
        v-model:period="listQuery.period"
        @success="getList"
      ></qzf-search-period>
      <selecttaxtype
        v-model:type="listQuery.type"
        @success="getList()"
      ></selecttaxtype>
      <selectuser
        code="bookkeeping"
        v-model:userId="listQuery.userId"
        @success="getList()"
        style="margin-left: 0;width: 170px;"
      ></selectuser>
      <selectcity v-model:citys="listQuery.districtCode" style="margin: 0;" @success="getList"></selectcity>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="全部" name="first"></el-tab-pane>
      <el-tab-pane label="零申报" name="second"></el-tab-pane>
      <el-tab-pane label="往期未结账公司" name="thr"></el-tab-pane>
      <div class="top_select">
       <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入公司名称/编号" v-model="listQuery.name" style="width:150px" @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel" v-if="activeName != 'thr'">
          <el-form style="margin:11px 0px 0px 0px;width:630px" class="styleForm">
            <el-form-item label="报税手机号 ：" :label-width="formLabelWidth">          
              <el-input v-model.trim="listQuery.tel" size="small" style="margin-left:5px;width:140px" placeholder="请输入报税手机号"></el-input>
            </el-form-item>
            <el-form-item label="公司类型：" :label-width="formLabelWidth"> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="margin-left:5px;width:140px">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="随手记 ：" :label-width="formLabelWidth">
              <el-input v-model="listQuery.jotDown" style="margin-left:5px;width:140px" size="small" placeholder="请输入随手记" />
            </el-form-item>
            <el-form-item label="是否零申报 ：" :label-width="formLabelWidth">  
              <el-radio-group v-model="listQuery.accountingType">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group> 
            </el-form-item>
            <el-form-item label="薪酬表状态 ：" :label-width="formLabelWidth">   
              <el-radio-group v-model="listQuery.salaryStatus">
                <el-radio label="">全部</el-radio>
                <el-radio label="0">未生成</el-radio>
                <el-radio label="1">已生成</el-radio>
                <el-radio label="2">部分生成</el-radio>
                <el-radio label="3">生成失败</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="个税取数 ：" :label-width="formLabelWidth">   
              <el-radio-group v-model="listQuery.gsStatus">
                <el-radio label="">全部</el-radio>
                <el-radio label="0">未取数</el-radio>
                <el-radio label="1">已取数</el-radio>
                <el-radio label="2">部分取数</el-radio>
                <el-radio label="3">取数失败</el-radio>
                <el-radio label="4">无需取数</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="税表取数 ：" :label-width="formLabelWidth">   
              <el-radio-group v-model="listQuery.taxStatus">
                <el-radio label="">全部</el-radio>
                <el-radio label="0">未取数</el-radio>
                <el-radio label="1">已取数</el-radio>
                <el-radio label="2">部分取数</el-radio>
                <el-radio label="3">取数失败</el-radio>
                <el-radio label="4">无需取数</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="财报取数 ：" :label-width="formLabelWidth">  
              <el-radio-group v-model="listQuery.kjStatus">
                <el-radio label="">全部</el-radio>
                <el-radio label="0">未取数</el-radio>
                <el-radio label="1">已取数</el-radio>
                <el-radio label="2">部分取数</el-radio>
                <el-radio label="3">取数失败</el-radio>
                <el-radio label="4">无需取数</el-radio>
              </el-radio-group> 
            </el-form-item>
            <el-form-item label="结账状态 ：" :label-width="formLabelWidth">   
              <el-radio-group v-model="listQuery.jz">
                <el-radio label="">全部</el-radio>
                <el-radio label="3">未结账</el-radio>
                <el-radio label="1">已结账</el-radio>
                <el-radio label="2">结账失败</el-radio>
              </el-radio-group> 
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div v-if="activeName != 'thr'">
        <el-button :disabled="!$buttonStatus('qsjz-plfz')" size="small" type="success" @click="batchCopySalary()"  :loading="loading">
          <el-icon><List /></el-icon><span  > 复制上期薪酬表</span>
        </el-button>
        <el-button @click="batchGetData" size="small" type="warning" :loading="getLoading" :disabled="!$buttonStatus('qsjz-plqs')">
          <el-icon><HelpFilled /></el-icon><span  > 批量取数</span>
        </el-button>
        <el-button :disabled="!$buttonStatus('jzdy_jz')" type="primary" size="small" v-loading.fullscreen.lock="fullscreenLoading" @click="allSettleOne">
          <el-icon><Money /></el-icon><span  > 结账</span>
        </el-button>
        <el-button :disabled="!$buttonStatus('jzdy_fjz')" type="danger" size="small" v-loading.fullscreen.lock="fullscreenLoading" @click="allFanSettleOne">
          <el-icon><Refresh /></el-icon><span  > 反结账</span>
        </el-button>
        <el-button size="small" type="primary" @click="daochu()"  :loading="daochuLoading" plain :disabled="!$buttonStatus('qsjz-dc')">
          <el-icon><Folder /></el-icon><span  >导出</span>
        </el-button>
      </div>
      <div v-else style="width:62%;float:right;text-align: right" >
        <el-button size="small" type="primary" @click="daochuCarry()"  :loading="daochuLoading" plain :disabled="!$buttonStatus('qsjz-dc')">
          <el-icon><Folder /></el-icon><span  >导出</span>
        </el-button>
      </div>
    </div>
    <el-table stripe ref="tableScroll" id="tableLazyLoad" :height="contentStyleObj" v-loading="listLoading" @sort-change="sortChange" :data="list" border highlight-current-row @select="handleSelectionChange"  @select-all="handleSelectionChangeAll">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" align="center" width="55" />
      <el-table-column  label="编号" align="center" prop="sort" width="80" fixed="left" sortable="custom">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
      </el-table-column>
      <el-table-column label="公司名称" fixed  min-width="240">
        <template #default="scope">
          <TagNameCopy :row="scope.row"  idKey="comId" :showAuthIcon="false"></TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column  prop="district" label="税局" width="65">
        <template #default="scope">
          <span>{{ $cityFilter(scope.row.district)  }}</span>  
        </template>
      </el-table-column>
      <el-table-column v-if="activeName != 'thr'" label="是否零申报" align="center" width="100">
        <template #default="scope">
          <span>{{ accountingTypeFifler(scope.row.accountingType) }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName != 'thr'" label="薪酬表状态" min-width="110">
        <template #default="scope">
          <el-tooltip placement="top" :disabled="!scope.row.salaryLog" effect="dark">
            <template #content>
              <div v-html="scope.row.salaryLog ? scope.row.salaryLog : ''"> </div>
            </template>
            <div class="item_icon">
              <i :class="filterIcon(scope.row.salaryStatus)"></i>
              <p :class="batchFilterWord(scope.row.salaryStatus)">{{ filterStatusSalary(scope.row.salaryStatus) }}</p>
              <el-tooltip effect="dark" placement="top" v-if="scope.row.salaryStatus == 1 || scope.row.salaryStatus == 2">
                <template #content>
                  <div v-html="'末次生成时间：' + scope.row.salaryTime"> </div>
                </template>
                <i class="iconfont icon-gantanhao1" :style="{color:(scope.row.salaryStatus == 1 ? '#67c23a' : '#e6a23c')}" style="margin-right:4px;"></i>
              </el-tooltip>
              <i class="iconfont icon-wenhao" style="color:red" v-if="scope.row.salaryLog">
              </i>
            </div>
          </el-tooltip>
        </template> 
      </el-table-column>
      <el-table-column v-if="activeName != 'thr'" label="个税取数" min-width="110">
        <template #default="scope">
          <el-tooltip effect="dark" placement="top" :disabled="!scope.row.gsLog">
            <template #content>
              <div v-html="scope.row.gsLog ? scope.row.gsLog : ''"> </div>
            </template>
            <div class="item_icon">
              <i :class="filterIcon(scope.row.gsStatus)"></i>
              <p :class="batchFilterWord(scope.row.gsStatus)">{{ filterStatus(scope.row.gsStatus) }}</p>
              <el-tooltip effect="dark" placement="top" v-if="scope.row.gsStatus == 1 || scope.row.gsStatus == 2">
                <template #content>
                  <div v-html="'末次取数时间：' + scope.row.gsTime"> </div>
                </template>
                <i class="iconfont icon-gantanhao1" :style="{color:(scope.row.gsStatus == 1 ? '#67c23a' : '#e6a23c')}" style="margin-right:4px;"></i>
              </el-tooltip>
              <i class="iconfont icon-wenhao" style="color:red" v-if="scope.row.gsLog">
              </i>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName != 'thr'" label="税表取数" min-width="110">
        <template #default="scope">
          <el-tooltip effect="dark" placement="top" :disabled="!scope.row.taxLog">
            <template #content>
              <div v-html="scope.row.taxLog ? scope.row.taxLog : ''"> </div>
            </template>
            <div class="item_icon">
              <i :class="filterIcon(scope.row.taxStatus)"></i>
              <p :class="batchFilterWord(scope.row.taxStatus)">{{ filterStatus(scope.row.taxStatus) }}</p>
              <el-tooltip effect="dark" placement="top" v-if="scope.row.taxStatus == 1 || scope.row.taxStatus == 2">
                <template #content>
                  <div v-html="'末次取数时间：' + scope.row.taxTime"> </div>
                </template>
                <i class="iconfont icon-gantanhao1" :style="{color:(scope.row.taxStatus == 1 ? '#67c23a' : '#e6a23c')}" style="margin-right:4px;"></i>
              </el-tooltip>
              <i class="iconfont icon-wenhao" :style="{color:(scope.row.taxStatus == 4 ? '#67c23a' : 'red')}" v-if="scope.row.taxLog">
              </i>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName != 'thr'" label="财报取数" min-width="110">
        <template #default="scope">
          <el-tooltip effect="dark" placement="top" :disabled="!scope.row.kjLog">
            <template #content>
              <div v-html="scope.row.kjLog ? scope.row.kjLog : ''"> </div>
            </template>
            <div class="item_icon">
              <i :class="filterIcon(scope.row.kjStatus)"></i>
              <p :class="batchFilterWord(scope.row.kjStatus)">{{ filterStatus(scope.row.kjStatus) }}</p>
              <el-tooltip effect="dark" placement="top" v-if="scope.row.kjStatus == 1 || scope.row.kjStatus == 2">
                <template #content>
                  <div v-html="'末次取数时间：' + scope.row.kjTime"> </div>
                </template>
                <i class="iconfont icon-gantanhao1" :style="{color:(scope.row.kjStatus == 1 ? '#67c23a' : '#e6a23c')}" style="margin-right:4px;"></i>
              </el-tooltip>
              <i class="iconfont icon-wenhao" style="color:red" v-if="scope.row.kjLog">
              </i>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName != 'thr'" label="结账状态" min-width="110">
        <template #default="scope">
            <el-tooltip
              effect="dark"
              placement="top-start"
              :disabled="!scope.row.settleLog || scope.row.settleStatus != 2"
            >
            <template #content>
              <el-scrollbar :maxHeight="350">
                <div v-html="scope.row.settleLog"></div>
              </el-scrollbar>
            </template>
            <div>
            <div class="item_icon" v-if="scope.row.settleStatus == '2'">
              <i class="iconfont icon-cuowu"></i>
              <p style="color:red">{{ settleStatusfifle(scope.row.settleStatus) }}</p>
              <i class="iconfont icon-wenhao" style="color:red"></i>
            </div>
            <div class="item_icon" v-else-if="scope.row.settleStatus == '4'">
              <i class="iconfont icon-duihao"></i>
              <p style="color:#67c23a">{{ settleStatusfifle(scope.row.settleStatus) }}</p>
            </div>
            <div class="item_icon" v-else>
              <i class="iconfont icon-gantanhao"></i>
              <p>{{ settleStatusfifle(scope.row.settleStatus) }}</p>
            </div>
          </div>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="随手记" min-width="150" v-if="activeName != 'thr' && $buttonStatus('jzkh_ssj')" show-overflow-tooltip>
        <template #default="scope">
          <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
          <span>{{scope.row.jotDown}}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName != 'thr'" label="操作" align="center" width="120">
        <template #default="scope">
          <el-button :disabled="!$buttonStatus('jzdy_fjz')" v-if="scope.row.settleStatus == '4'" @click="fansettleOne(scope.row.comId)" type="text" plain size="small" v-loading.fullscreen.lock="fullscreenLoading">
            <el-icon><Refresh /></el-icon><span  > 反结账</span>
          </el-button>
          <el-button :disabled="!$buttonStatus('jzdy_jz')" v-else type="text" @click="settleOne(scope.row.comId)" size="small" v-loading.fullscreen.lock="fullscreenLoading">
            <el-icon><Money /></el-icon><span  > 结账</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName == 'thr'" label="最后结账账期" align="center" min-width="220">
        <template #default="scope">
          {{scope.row.curPeriod}}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="batchSettle"/>
    </div>
    </el-tabs>
  </div>
  <!-- 批量取数的 -->
  <el-dialog :close-on-click-modal="false"
    v-model="dialogPlqsVisible"
    title="批量取数"
    width="40%"
    destroy-on-close
  >
    <el-checkbox
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
      >全选</el-checkbox
    >
    <el-checkbox-group
      v-if="this.listQuery.period.slice(4,6) == '03' || this.listQuery.period.slice(4,6) == '06' || this.listQuery.period.slice(4,6) == '09' || this.listQuery.period.slice(4,6) == '12'"
      v-model="checkedCities"
      @change="handleCheckedCitiesChange"
    >
      <el-checkbox v-for="city in cityOptions" :label="city" :key="city"
        >{{city}}</el-checkbox
      >
    </el-checkbox-group>
    <el-checkbox-group
      v-else
      v-model="checkedCities2"
      @change="handleCheckedCitiesChange"
    >
      <el-checkbox v-for="city in cityOptions" :label="city" :key="city"
        >{{city}}</el-checkbox
      >
    </el-checkbox-group>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogPlqsVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="makeSureBatchGetData">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 复制上期薪酬表 -->
  <el-dialog :close-on-click-modal="false"
    v-model="dialogCopyVisible"
    title="复制上期薪酬表"
    width="30%"
    destroy-on-close
  >
    <el-form :model="form">
      <el-form-item label="" label-width="60px">
        <el-input class="input" size="small" v-model="form.bfb" :disabled="inputStatus" style="width:120px"></el-input>% <span style="color: #17a2b8;margin-left: 20px;cursor: pointer;" @click="inputStatus = false">解锁编辑</span>
      </el-form-item> 
      <el-form-item label-width="60px">
        <div style="letter-spacing: 1px;">备注：薪酬表复制，默认直接复制<br>您也可以修改百分比批量自动修改工资表</div>
      </el-form-item>  
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogCopyVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="copySure">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <jotDown ref="jotDown" @jotDownUpdate="updateNote"/>
</template>

<script>
import { currentAccountPeriod } from "@/utils";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import { companyCarryOverList ,retrievalSettlementCompanyList  ,exRetrievalSettlementCompany,exCompanyCarryOver} from "@/api/company"
import { reverseCheckout, checkOut } from '@/api/carryover.js'
import { oneClickReplicationArr } from "@/api/salary"
import { carryOverOne }  from "@/api/carryover"
import jotDown from '@/components/jotDown'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "batch-settle",
  components: {
    selecttaxtype,
    selectcity,
    jotDown,
    TagNameCopy,
    TableSortCell
  },
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      activeName: "first",
      contentStyleObj:{}, //高度变化
      formLabelWidth:'120px',
      listQuery: {
        name: "",
        period: currentAccountPeriod(),
        userId: null,
        page: 1,
        limit: 20,
        jz: "",
        accountingType: 0,
        salaryStatus: null,
        type: "",
        gsStatus: "",
        taxStatus: "",
        kjStatus: "",
        tel:'',
        companyType:'',
        jotDown:'',
      },
      list:[],
      total:0,
      fullscreenLoading:false,
      selects:[],
      loading:false,
      getLoading:false,
      dialogPlqsVisible:false,
      checkAll: false,
      isIndeterminate: true,
      cityOptions:["一键生成凭证","薪酬资产结转","税表取数","结转损益"],
      checkedCities:["一键生成凭证","薪酬资产结转","税表取数","结转损益"],
      checkedCities2:["一键生成凭证","薪酬资产结转","税表取数","结转损益"],
      seletcs2:undefined,
      noteContent:"",
      dialogCopyVisible:false,
      form:{
        bfb:100,
        comIds:[]
      },
      inputStatus:true,
      listLoading:false,
      daochuLoading:false,
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false
    };
  },
  mounted() {
    this.contentStyleObj=this.$getHeight(246)
    this.listQuery.limit = localStorage.getItem('batchSettle') ? localStorage.getItem('batchSettle')*1 : 20
    let from = this.$route.query.from;
    if (from == "hint") {
      this.activeName = "thr";
    }
    this.getList();
  },
  methods: {
    // 重置
    cancel(){
      let originLimit =this.listQuery.limit
       this.listQuery={
         name: "",
        period: currentAccountPeriod(),
        userId: [],
        page: 1,
        limit: originLimit,
        jz: null,
        salaryStatus: null,
        type: "",
        gsStatus: null,
        taxStatus: null,
        kjStatus: null,
        tel:'',
        companyType:'',
        jotDown:'',
       }
      if (this.activeName == "first") {
        this.listQuery.accountingType = 0;
      } else if (this.activeName == "second") {
        this.listQuery.accountingType = 1;
      } else if (this.activeName == "thr") {
        this.listQuery.accountingType = 3;
      }
      this.getList()
    },
    getList() {
      if(this.activeName == 'first' || this.activeName == 'second'){
        this.listLoading = true
        retrievalSettlementCompanyList(this.listQuery).then(res=>{
          this.listLoading = false
          if(res.data.msg == 'success'){
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
            this.setScroll()
          }
        })
      }else{
        this.listQuery.accountingType = 3;
        this.listLoading = true
        companyCarryOverList(this.listQuery).then(res=>{
          this.listLoading = false
          if(res.data.msg == 'success'){
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
            this.setScroll()
          }
        })
      }
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    handleSelectionChange(val){
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.allChecked = true
      }else{
        this.selects = []
        this.allChecked = false
      }
    },
    // 结账
    settleOne(id){
      this.fullscreenLoading = true;
      let param = {
        comId:id,
        period:this.listQuery.period
      }
      checkOut([param]).then(res=>{
        this.fullscreenLoading = false;
        this.getList()
        if(res.data.msg == "success"){
          if(res.data.data.status){
            this.$qzfMessage("结账成功")
          }     
        }
      })
    },
    allSettleOne(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.fullscreenLoading = true;
      let param = []
      this.selects.map(v=>{
        param.push({
          comId:v.comId,
          period:this.listQuery.period
        })
      })
      checkOut(param).then(res=>{
        this.fullscreenLoading = false;
        this.getList()
        if(res.data.msg == "success"){
          if(res.data.data.status){
            this.$qzfMessage("结账成功")
          }     
        }
      })
    },
      // 反结账
      fansettleOne(id){
      this.fullscreenLoading = true;
      let param = {
        comId:id,
        period:this.listQuery.period
      }
      reverseCheckout([param]).then(res=>{
        this.fullscreenLoading = false;
        if(res.data.msg == "success"){
            this.$qzfMessage("反结账成功")
            this.getList()
          } else{
            this.$qzfMessage("反结账失败", 2)
          }      
        }
      )
    },
    allFanSettleOne(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.fullscreenLoading = true;
      let param = []
      this.selects.map(v=>{
        param.push({
          comId:v.comId,
          period:this.listQuery.period
        })
      })
      reverseCheckout(param).then(res=>{
        this.fullscreenLoading = false;
        if(res.data.msg == "success"){
            this.$qzfMessage("反结账成功")
            this.getList()
          } else{
            this.$qzfMessage("反结账失败", 2)
          }      
        }
      )
    },
    //更新随手记之后的回调
    updateNote(id,text){
      this.list.map(v=>{
        if(v.comId == id){
          v.jotDown = text
        }
      })
    },
    // 批量复制往期薪酬
    batchCopySalary(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let comIds = []
      this.selects.map(v => {
        comIds.push(v.comId)
      })
      this.inputStatus = true
      this.form.comIds = comIds
      this.form.bfb = 100
      this.dialogCopyVisible = true
    },
    //导出
    daochu(){
      this.daochuLoading = true
      let ids = []
      this.selects.map(v => {
        ids.push(v.comId)
      })
      let param = {
        comIds:ids,
        info:this.listQuery
      }
      exRetrievalSettlementCompany(param).then(res=>{
        this.daochuLoading = false
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    daochuCarry(){
      this.daochuLoading = true
      let ids = []
      this.selects.map(v => {
        ids.push(v.comId)
      })
      let param = {
        comIds:ids,
        info:this.listQuery
      }
      exCompanyCarryOver(param).then(res=>{
        this.daochuLoading = false
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    copySure(){
      this.form.period = this.listQuery.period;
      this.form.bfb = Number(this.form.bfb)
      if(isNaN(this.form.bfb)){
        this.$qzfMessage('请输入合法数字',1)
        this.form.bfb = 100
        return
      }
      this.loading = true
      oneClickReplicationArr(this.form).then(res=>{
        this.loading = false
        if(res.data.msg == 'success'){
          this.dialogCopyVisible = false
          this.$qzfMessage('操作成功');
          this.getList()
        }
      })
    },
    batchGetData(){
      let comIds = []
      this.selects.map(v => {
        comIds.push(v.comId)
      })
      this.seletcs2 = {
        period:this.listQuery.period,
        comIds:comIds
      }
      if(!this.seletcs2.comIds || this.seletcs2.comIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let settleStatuss = false
      let comName = ""
      this.selects.map(v => {
        if(v.settleStatus == 4){
          comName = v.name
          settleStatuss = true
          return
        }
      })
      if(settleStatuss){
        this.$qzfMessage(comName +"本账期已结账,请先反结账", 2)
        return
      }
      this.dialogPlqsVisible = true
    },
    handleCheckAllChange(val) {
      if(this.listQuery.period.slice(4,6) == '03' || this.listQuery.period.slice(4,6) == '06' || this.listQuery.period.slice(4,6) == '09' || this.listQuery.period.slice(4,6) == '12'){
        this.checkedCities = val ? this.cityOptions : []
      }else{
        this.checkedCities2 = val ? this.cityOptions : []
      }
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cityOptions.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cityOptions.length
    },
    makeSureBatchGetData(){
      if(this.listQuery.period.slice(4,6) == '03' || this.listQuery.period.slice(4,6) == '06' || this.listQuery.period.slice(4,6) == '09' || this.listQuery.period.slice(4,6) == '12'){
        this.checkedCities = this.checkedCities
      }else{
        this.checkedCities = this.checkedCities2
      }
      this.seletcs2.types = this.checkedCities
      this.getLoading = true
      carryOverOne(this.seletcs2).then(res => {
        this.getLoading = false
        this.dialogPlqsVisible = false
        if(res.data.msg == 'success') {
          this.$qzfMessage("操作成功")
          this.getList()
        }
      })
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
    smallNotes(row){
      this.$refs.jotDown.init(row.name,row.comId,this.listQuery.period)
    },
    batchFilterWord(status){
      if(status == 0 || status == ''){
        return "wsb";
      }else if(status == 1){
        return 'sbcg2'
      }else if(status == 2){
        return 'yczt'
      }else if(status == 3){
        return 'sbyc2'
      }else if(status == 4){
        return 'sbcg2'
      }else if(status == 10){
        return 'sbz'
      }
    },
    filterStatus(status){
      if(status == 0 || status == ''){
        return "未取数";
      }else if(status == 1){
        return '已取数'
      }else if(status == 2){
        return '部分取数'
      }else if(status == 3){
        return '取数失败'
      }else if(status == 4){
        return '无需取数'
      }else if(status == 10){
        return '排队中'
      }
    },
    filterStatusSalary(status){
      if(status == 0 || status == ''){
        return "未生成";
      }else if(status == 1){
        return '已生成'
      }else if(status == 2){
        return '部分生成'
      }else if(status == 3){
        return '生成失败'
      }else if(status == 4){
        return '无需生成'
      }
    },
    filterIcon(status){
      if(status == 0 || status == ''){
        return "iconfont icon-gantanhao";
      }else if(status == 1){
        return 'iconfont icon-duihao'
      }else if(status == 2){
        return 'iconfont icon-duihao duihao-yc'
      }else if(status == 3){
        return 'iconfont icon-cuowu'
      }else if(status == 4){
        return 'iconfont icon-duihao'
      }else if(status == 10){
        return 'iconfont icon-info'
      }
    },
    handleClick(tab) {
      this.activeName = tab.props.name
      this.listQuery.page = 1;
      if (tab.props.name == "first") {
        this.listQuery.accountingType = 0;
      } else if (tab.props.name == "second") {
        this.listQuery.accountingType = 1;
      } else if (tab.props.name == "thr") {
        this.listQuery.accountingType = 3;
      }
      this.getList()
    },
    settleStatusfifle(e) {
      if(e == '2') {
        return '结账失败'
      }else if(e == '4'){
        return '已结账'
      }else{
        return '未结账'
      }
    },
    //是否零申报过滤
    accountingTypeFifler(e) {
      if(e == 1){
        return "是"
      }else{
        return "否"
      }
    },
    typeFifler(e) {
      if(e == "1"){
        return "增值税小规模纳税人"
      }else{
        return "增值税一般纳税人"
      }
    },
    toStatus4(val) {
      switch (val) {
        case 1:
          return "已计提";
        case 2:
          return "未计提";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select{
  display:flex;
  align-items:center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.el-radio{
  margin-right: 12px;
 }
.item_icon{
  display: inline-block;
  // padding: 1px 12px;
  padding-right: 5px;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-info{
    color: #409eff
  }
  p{
    display: inline-block;
    font-size: 13px;
    //color: #333;
    margin:0 4px;
  }
}
</style>
<style lang="scss">
  .classPrompt{
    .el-message-box__container{
      color:red
    }
}
</style>
